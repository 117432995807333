import 'photoswipe/dist/photoswipe.css'
import { useTranslation, Trans } from 'react-i18next';
import { Gallery, Item } from 'react-photoswipe-gallery';
import ContactForm from './ContactForm';

const Galerie = () => {
  const { t, i18n } = useTranslation();

  const smallItemStyles = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '100%',
    maxHeight: '100%',
  }
  
  return (
    <div className='page-galerie'>
      <div className="container-child">
        <h1 className='text-center green mb-10'><Trans i18nKey="galerie.h1">DÉCOUVREZ LES PLUS BELLES PHOTOS DE AVALON CARP LAKE.</Trans></h1>
          
        <Gallery>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            <Item
              original="images/galerie/img-galerie-46.jpg"
              thumbnail="images/galerie/img-galerie-46.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-46.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-47.jpg"
              thumbnail="images/galerie/img-galerie-47.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-47.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-48.jpg"
              thumbnail="images/galerie/img-galerie-48.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-48.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-49.jpg"
              thumbnail="images/galerie/img-galerie-49.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-49.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-50.jpg"
              thumbnail="images/galerie/img-galerie-50.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-50.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-51.jpg"
              thumbnail="images/galerie/img-galerie-51.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-51.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-52.jpg"
              thumbnail="images/galerie/img-galerie-52.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-52.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-53.jpg"
              thumbnail="images/galerie/img-galerie-53.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-53.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-54.jpg"
              thumbnail="images/galerie/img-galerie-54.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-54.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-55.jpg"
              thumbnail="images/galerie/img-galerie-55.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-55.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-56.jpg"
              thumbnail="images/galerie/img-galerie-56.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-56.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-57.jpg"
              thumbnail="images/galerie/img-galerie-57.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-57.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-58.jpg"
              thumbnail="images/galerie/img-galerie-58.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-58.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-59.jpg"
              thumbnail="images/galerie/img-galerie-59.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-59.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-60.jpg"
              thumbnail="images/galerie/img-galerie-60.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-60.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-61.jpg"
              thumbnail="images/galerie/img-galerie-61.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-61.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-62.jpg"
              thumbnail="images/galerie/img-galerie-62.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-62.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-63.jpg"
              thumbnail="images/galerie/img-galerie-63.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-63.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-64.jpg"
              thumbnail="images/galerie/img-galerie-64.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-64.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-65.jpg"
              thumbnail="images/galerie/img-galerie-65.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-65.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-66.jpg"
              thumbnail="images/galerie/img-galerie-66.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-66.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-67.jpg"
              thumbnail="images/galerie/img-galerie-67.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-67.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-68.jpg"
              thumbnail="images/galerie/img-galerie-68.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-68.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-69.jpg"
              thumbnail="images/galerie/img-galerie-69.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-69.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-70.jpg"
              thumbnail="images/galerie/img-galerie-70.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-70.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-71.jpg"
              thumbnail="images/galerie/img-galerie-71.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-71.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-72.jpg"
              thumbnail="images/galerie/img-galerie-72.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-72.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-73.jpg"
              thumbnail="images/galerie/img-galerie-73.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-73.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-74.jpg"
              thumbnail="images/galerie/img-galerie-74.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-74.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-75.jpg"
              thumbnail="images/galerie/img-galerie-75.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-75.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-76.jpg"
              thumbnail="images/galerie/img-galerie-76.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-76.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-77.jpg"
              thumbnail="images/galerie/img-galerie-77.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-77.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-78.jpg"
              thumbnail="images/galerie/img-galerie-78.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-78.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-79.jpg"
              thumbnail="images/galerie/img-galerie-79.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-79.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-80.jpg"
              thumbnail="images/galerie/img-galerie-80.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-80.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-81.jpg"
              thumbnail="images/galerie/img-galerie-81.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src="images/galerie/img-galerie-81.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

            <Item
              original="images/galerie/img-galerie-82.jpg"
              thumbnail="images/galerie/img-galerie-82.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-82.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            
            <Item
              original="images/galerie/img-galerie-83.jpg"
              thumbnail="images/galerie/img-galerie-83.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-83.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-84.jpg"
              thumbnail="images/galerie/img-galerie-84.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-84.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
            <Item
              original="images/galerie/img-galerie-85.jpg"
              thumbnail="images/galerie/img-galerie-85.jpg"
              alt="Photo Avalon Carp Lake"
              width="718"
              height="508"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src="images/galerie/img-galerie-85.jpg"
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>

          </div>
        </Gallery>

        {/* <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
          <img src="images/galerie/img-galerie-46.jpg" alt="" />
          <img src="images/galerie/img-galerie-47.jpg" alt="" />
          <img src="images/galerie/img-galerie-48.jpg" alt="" />
          <img src="images/galerie/img-galerie-49.jpg" alt="" />
          <img src="images/galerie/img-galerie-50.jpg" alt="" />
          <img src="images/galerie/img-galerie-51.jpg" alt="" />
          <img src="images/galerie/img-galerie-52.jpg" alt="" />
          <img src="images/galerie/img-galerie-53.jpg" alt="" />
          <img src="images/galerie/img-galerie-54.jpg" alt="" />
          <img src="images/galerie/img-galerie-55.jpg" alt="" />
          <img src="images/galerie/img-galerie-56.jpg" alt="" />
          <img src="images/galerie/img-galerie-57.jpg" alt="" />
          <img src="images/galerie/img-galerie-58.jpg" alt="" />
          <img src="images/galerie/img-galerie-59.jpg" alt="" />
          <img src="images/galerie/img-galerie-60.jpg" alt="" />
          <img src="images/galerie/img-galerie-61.jpg" alt="" />
          <img src="images/galerie/img-galerie-62.jpg" alt="" />
          <img src="images/galerie/img-galerie-63.jpg" alt="" />
          <img src="images/galerie/img-galerie-64.jpg" alt="" />
          <img src="images/galerie/img-galerie-65.jpg" alt="" />
          <img src="images/galerie/img-galerie-66.jpg" alt="" />
          <img src="images/galerie/img-galerie-67.jpg" alt="" />
          <img src="images/galerie/img-galerie-68.jpg" alt="" />
          <img src="images/galerie/img-galerie-69.jpg" alt="" />
          <img src="images/galerie/img-galerie-70.jpg" alt="" />
          <img src="images/galerie/img-galerie-71.jpg" alt="" />
          <img src="images/galerie/img-galerie-72.jpg" alt="" />
          <img src="images/galerie/img-galerie-73.jpg" alt="" />
          <img src="images/galerie/img-galerie-74.jpg" alt="" />
          <img src="images/galerie/img-galerie-75.jpg" alt="" />
          <img src="images/galerie/img-galerie-76.jpg" alt="" />
          <img src="images/galerie/img-galerie-77.jpg" alt="" />
          <img src="images/galerie/img-galerie-78.jpg" alt="" />
          <img src="images/galerie/img-galerie-79.jpg" alt="" />
          <img src="images/galerie/img-galerie-80.jpg" alt="" />
          <img src="images/galerie/img-galerie-81.jpg" alt="" />
          <img src="images/galerie/img-galerie-82.jpg" alt="" />
          <img src="images/galerie/img-galerie-83.jpg" alt="" />
          <img src="images/galerie/img-galerie-84.jpg" alt="" />
          <img src="images/galerie/img-galerie-85.jpg" alt="" />
        </div> */}
      </div>

      <div className="container-full bg-beige">
        <div className="container-child">
          <ContactForm />
        </div>
      </div>
    </div>
  )
}

export default Galerie;